import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/structure/layout.js'
import Seo from '../components/essentials/seo.js'

const Privacy = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Seo title={t('title') | Privacy} />
    
      <div className="">
        <h1>Privacidad</h1>        
        <p>Against De Watch Sl. te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser recabados durante la navegación a través del sitio Web https://profile.health/</p>
        <p>En este sentido, Against De Watch Sl. cumple con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).</p>
        <p>
          Identidad del responsable
          Titular: Against de Watch SL
          NIF: ESB40556532
          Domicilio: Calle de O'Donell 25, Madrid (España)
          Correo electrónico: contact@profile.health
        </p>
        <h2>Principios aplicados en el tratamiento de datos</h2>
        <p>En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos (RGPD):</p>
        <p>Principio de licitud, lealtad y transparencia:</p>
        <p></p>
      </div>

    </Layout>
  );
};

export default Privacy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index", "landing"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;